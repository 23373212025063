import React from "react"
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { Grid, Margin, Video, } from '@components/atoms'
import { H0, H2, H3 } from '@components/atoms/BlockRenderer'
import { GridItem, GridItemScrollStrip, Hero, FridayFeature } from '@components/molecules'
import { TransitionMask, Newsletter } from '@components/organisms'
import { Input, Submit } from '@components/organisms/Newsletter'
import { SocialA } from '@components/organisms/Footer'
import { PageProps } from '@types'
import { palette } from '@theme'
import Lottie from "lottie-react"
import FridayFivesLogo from '../assets/images/friday-fives.json'
import { getCdnPath } from '@utils'
import { Helmet } from 'react-helmet'


const FridayFivesPage = ({data: { 
  sanityPage: {
    heroType, 
    heroHeight,
    heroImageConditional, 
    heroVideoConditional,
    heroFX,
    gridItems,
    gridSnap,
    inverted,
    _rawIntroduction,
    fridayFivesFeatures,
    cornerVideo,
    shareImage,
    description,
    title,
  }
}}: PageProps) => {
  const parsedTitle = `TWA: ${title}`
  const meta = [
    { property: "og:title", content: parsedTitle },
    { name: "twitter:title", content: parsedTitle },
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },
  ]
  if(shareImage) {
    meta.push({ property: "og:image", content:  getCdnPath(`${shareImage.asset.url}?w=640&fm=jpg`) })
    meta.push({ name: "twitter:image", content:  getCdnPath(`${shareImage.asset.url}?w=640&fm=jpg`) })
  }
  // const data = useStaticQuery(graphql`
  //   query {
  //     allInstaNode {
  //       edges {
  //         node {
  //           id
  //         }
  //       }
  //     }
  //   }
  // `)
  return (
    <>
      <Helmet {...{meta}} title={parsedTitle} >
        <title>{parsedTitle}</title>
      </Helmet>

      <Hero {...{ heroType, heroHeight, heroImageConditional, heroVideoConditional, heroFX }} />
      
      <StyledMargin>
        <Grid {...{ gridSnap }} >

          <FridayLogo 
            animationData={FridayFivesLogo} 
            loop={true} 
            autoplay={true} 
          />
          
          <StyledH1>
            Every Friday, we highlight five things we have on our radar that we think should be on yours, too.
          </StyledH1>
          
          <StyledH2>
            If you’re interested in capping off your week with some good vibes, read the latest or subscribe here.
          </StyledH2>
          
          <NewsletterWrapper>
            {cornerVideo && <Video autoPlay muted loop playsInline src={cornerVideo.asset.url} />}
            <StyledNewsletter submitLabel="Subscribe" />
          </NewsletterWrapper>
          
          <ConnectHeader>Best of Friday Fives</ConnectHeader>
          <GridItemScrollStrip stripStyle="scroll" >
            {fridayFivesFeatures?.map((feature: any, i: number) => (
              <FridayFeature {...feature} key={i} />
            ))}
          </GridItemScrollStrip>
          
          <LinkToMedium
            href="https://workingassembly.medium.com/"
            target="_blank"
          >Explore Past Issues</LinkToMedium>

          <ConnectHeader>Connect With Us</ConnectHeader>
          <Social>
            <li>
              <SocialA href="https://www.instagram.com/workingassembly/" target="_blank" arrowVisible >Instagram</SocialA>
            </li>
            <li>
              <SocialA href="https://www.linkedin.com/company/the-working-assembly" target="_blank" arrowVisible >LinkedIn</SocialA>
            </li>
          </Social>
          {gridItems.filter(p => !!p).map((item) => (
            <GridItem key={item._key} {...item} />
          ))}
          
        </Grid>
      </StyledMargin>
            
      <TransitionMask {...{ inverted }} />
    </>
  )
}


const FridayLogo = styled(props => <Lottie {...props} />)`
  grid-column: 2 / span 4;
  @media only screen and (min-width: 744px) {
    grid-column: 3 / span 4;
  }
`



const StyledH1 = styled(props => <H0 {...props} />)`
  grid-column-start: 1;
  grid-column-end: span 6;
  @media only screen and (min-width: 744px) {
    grid-column: 3 / span 5;
    grid-row: span 2;
  }

`


const StyledH2 = styled(props => <H3 {...props} />)`
  grid-column-start: 1;
  grid-column-end: span 6;
  @media only screen and (min-width: 744px) {
    grid-column: 9 / span 4;
  }
  
`


const StyledMargin = styled(props => <Margin {...props} />)`
  padding: 28vw 0 10vw 0;
  @media only screen and (min-width: 744px) {
    padding: 12vw 0 5vw 0;
  }
`


const NewsletterWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: span 6;
  @media only screen and (min-width: 744px) {
    grid-column: 9 / span 3;
  }
`


const Header = styled.header`
  @media only screen and (max-width: 743px) {
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: span 6;
    h1 {
      margin-bottom: 1em;
    }
  }
  @media only screen and (min-width: 744px) {
    grid-column-start: 3;
    grid-column-end: span 6;
  }
`




const ConnectHeader = styled(props => <H2 {...props} />)`
  margin-top: 13vw;
  grid-column-start: 1;
  grid-column-end: span 6;
  @media only screen and (min-width: 744px) {
    margin-top: 13vw;
    grid-column-start: 3;
    grid-column-end: span 7;
  }
`


const Social = styled.ul`
  grid-column-start: 1;
  grid-column-end: span 6;
  li {
    display: inline-block;
    margin-right: 15px;
  }
  @media only screen and (min-width: 744px) {
    margin-top: 13vw;
    grid-column-start: 10;
    grid-column-end: span 3;
    text-align: right;
    li {
      margin-right: 0px;
      margin-left: 15px;
    }
  }
  font-family: 'Founders', sans-serif;
  font-weight: 300;
  font-size: 22px;
  line-height: 1.2em;
  @media only screen and (min-width: 835px) {
    font-size: 1.67vw;
  }
`


const ButtonStyle = `
  background-color: white;
  color: ${palette.thunder};
  font-family: 'Founders', sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.2em;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 0.5em;
  -webkit-appearance: none;
  border-radius: 0px;
`


const StyledNewsletter = styled(props => <Newsletter {...props} />)`
  ${Input} {
    padding: 2em 0 0.5em 0;
    border-bottom: 1px solid white;
  }
  ${Submit} {
    margin-top: 4em;
    ${ButtonStyle}
    padding: 10px 40px;
  }
`


const LinkToMedium = styled.a`
  grid-column-start: 1;
  grid-column-end: span 4;
  @media only screen and (min-width: 744px) {
    grid-column-start: 3;
    grid-column-end: span 4;
  }
  ${ButtonStyle}
  padding: 10px 0;
  text-align: center;
  text-decoration: none;
`



export const query = graphql`
  query FridayFivesPageQuery {
    sanityPage(slug: {current: {eq: "friday-fives"}}) {
      ...pageFields
      cornerVideo {
        asset {
          url
        }
      }
      fridayFivesFeatures {
        description
        featureDate
        link
        featureImage {
          asset {
            url
            metadata {
              dimensions {
                aspectRatio
                height
                width
              }
            }
          }
        }
      }
    }
  }
`

export default FridayFivesPage
